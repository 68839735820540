import React, { useState, useEffect } from 'react';
import { getLocaleData } from '@mb/lib/i18n';
import { IntlProvider } from 'react-intl';
import AutocompleteResultsContainer from './containers/AutocompleteResultsContainer/AutocompleteResultsContainer';
import SearchFormContainer from './containers/SearchFormContainer/SearchFormContainer';
import { ProviderWrapper } from './ProviderWrapper';
// import { Provider } from 'react-redux';
// import store from './redux/store';

const SearchBar: React.FC = () => {
  const [langData, setLangData] = useState({
    currentLocale: 'en',
    messages: undefined,
  });

  useEffect(() => {
    handleLangData();
  }, []);

  const handleLangData = async () => {
    const data = await getLocaleData();
    // @ts-expect-error // TODO: fix types
    setLangData(data);
  };

  return (
    <IntlProvider
      messages={langData?.messages}
      locale={langData?.currentLocale}
      defaultLocale="en"
    >
      <ProviderWrapper>
        <SearchFormContainer />
        <AutocompleteResultsContainer />
      </ProviderWrapper>
    </IntlProvider>
  );
};

export default SearchBar;
