import React from 'react';
import { createRoot } from 'react-dom/client';

import { ThemeContainer } from 'ui-widgets';
import SearchBar from '../../../components/SearchBar/SearchBar';

const WrappedSearchForm = () => {
  return (
    <ThemeContainer>
      <SearchBar />
    </ThemeContainer>
  );
};

const container = document.getElementById('headerSearchForm');
const root = createRoot(container!);
root.render(<WrappedSearchForm />);
